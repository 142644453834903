<template>
  <LkAssistanceProvidePerson mode="auth"></LkAssistanceProvidePerson>
</template>

<script>
import LkAssistanceProvidePerson from '@component/Module/EditProfile/LkAssistanceProvidePerson.vue';

export default {
  name: 'EditProfile',
  components: {
    LkAssistanceProvidePerson,
  },
};
</script>
